import myRequest from "@/utils/request";

export function getTermsDetail(params: any) {
    return myRequest.postRequest('/other/commonId', params).then(resp => {
        return resp;
    });
}

export function getCommonDetail(params: any) {
    return myRequest.postRequest('/other/commonValue', params).then(resp => {
        return resp;
    });
}

export function getCommonGroup(params: any) {
    return myRequest.postRequest('/other/commonGroupValue', params).then(resp => {
        return resp;
    });
}
