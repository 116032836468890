import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import ElementPlus, {ElMessage} from 'element-plus';
import 'element-plus/dist/index.css';
import 'element-plus/theme-chalk/display.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

/*md编辑器开始*/
// @ts-ignore
import VMdEditor from '@kangc/v-md-editor'
import '@kangc/v-md-editor/lib/style/base-editor.css';
// @ts-ignore
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js'
import '@kangc/v-md-editor/lib/theme/style/github.css';
import hljs from 'highlight.js';
// 按需引入语言包
import json from 'highlight.js/lib/languages/json';
import java from 'highlight.js/lib/languages/java';
import python from 'highlight.js/lib/languages/python';
// @ts-ignore
import localStorageDb from './utils/localStorage';
import myRequest from "./utils/request";
// @ts-ignore
import VueWechatTitle from 'vue-wechat-title';

/*vant ui*/
import 'vant/lib/index.css';

hljs.registerLanguage('json', json);
hljs.registerLanguage('java', java);
hljs.registerLanguage('python', python);

VMdEditor.use(githubTheme, {
    Hljs: hljs,
});
/*md编辑器结束*/

/*按需引入vant的组件*/
import {Icon, DropdownMenu, DropdownItem, Toast, NoticeBar} from 'vant';

const app = createApp(App)
app.use(router).use(ElementPlus).use(VMdEditor).use(VueWechatTitle)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(Icon).use(DropdownMenu).use(DropdownItem).use(Toast).use(NoticeBar)
app.mount('#app')

app.config.globalProperties.$request = myRequest;

import baiduAnalytics from "vue-baidu-analytics";

app.use(baiduAnalytics, {
    router: router,
    siteIdList: ['d795eaf2a94750d7468599798f7dd83e'], //在上面生成的script中hm.src后面的字符串
    isDebug: false,
});


router.beforeEach((to, from, next) => {
    // to.meta.keepAlive = !(to.path == '/' && from.path == '/account/signin');
    if (to.matched.some(m => m.meta.auth)) {
        if (localStorageDb.get("Authorization")) {
            next()
        } else if (to.path !== '/') {
            next({path: '/account/signin'});
            ElMessage.error('请先登录！')
        }
    } else {
        next()
    }
});

/*新页面进入后，回到顶部*/
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
});
