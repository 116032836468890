import myRequest from "@/utils/request";
import localStorageDb from "@/utils/localStorage";

export function updateUserInfo(params: any) {
    return myRequest.postRequest('/user/updateUserInfo', params).then(resp => {
        return resp;
    });
}

export function updatePwd(params: any) {
    return myRequest.postRequest('/user/updatePwd', params).then(resp => {
        return resp;
    });
}

export function logOut() {
    return myRequest.postRequest('/auth/logout').then(resp => {
        localStorageDb.remove("USER");
        localStorageDb.remove("Authorization");
        return resp
    })
}
