// 判断多长时间前，time：2019-12-12 12:12:12，超过365天显示年月日
export const timeAgo = (time: string) => {
    const between = Date.now() / 1000 - Number(new Date(time).getTime() / 1000)
    if (between < 180) {
        return '刚刚'
    } else if (between < 3600) {
        return ~~(between / 60) + '分钟前'
    } else if (between < 86400) {
        return ~~(between / 3600) + '小时前'
    } else if (between < 31536000) {
        return ~~(between / 86400) + '天前'
    } else {
        return ~~(between / 31536000) + '年前'
    }
}
