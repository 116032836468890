<template>
    <div class="page-topics" v-wechat-title="$route.meta.title+' - 黑鸭'">
        <Header/>
        <router-view v-slot="{ Component }">
            <keep-alive>
                <component :is="Component" v-if="$route.meta.keepAlive" class="container" :key="$route.name"></component>
            </keep-alive>
            <component :is="Component" v-if="!$route.meta.keepAlive" class="container" :key="$route.name"></component>
        </router-view>
        <Footer/>
    </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import '@/assets/css/common.scss'

export default {
    name: 'HomeView',
    components: {
        Header, Footer
    }
}
</script>
<style>

.page-topics {
    display: block;
}

</style>
