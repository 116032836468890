import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            keepAlive: true,
            title: '首页'
        }
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '@/views/other/About.vue'),
        meta: {
            title: '关于我们'
        }
    },
    {
        path: '/topics/:uuid',
        name: 'topics',
        component: () => import(/* webpackChunkName: "about" */ '@/views/topic/TopicDetail.vue'),
        meta: {
            title: '主题详情'
        }
    },
    {
        path: '/topics/new',
        name: 'topicCreate',
        component: () => import(/* webpackChunkName: "about" */ '@/views/topic/TopicCreate.vue'),
        meta: {
            title: '创建主题',
            auth: true
        }
    },
    {
        path: '/user',
        name: 'User',
        children: [
            {
                path: 'center/:type',
                name: 'userCenter',
                component: () => import(/* webpackChunkName: "about" */ '@/views/user/UserCenter.vue'),
                meta: {
                    title: '用户中心',
                    auth: true
                }
            },
            {
                path: 'update/:type',
                name: 'UserUpdate',
                component: () => import(/* webpackChunkName: "about" */ '@/views/user/UserUpdate.vue'),
                meta: {
                    title: '更新用户信息',
                    auth: true
                }
            },
        ]
    },
    {
        path: '/account',
        name: 'account',
        children: [
            {
                path: 'signin',
                name: 'login',
                component: () => import(/* webpackChunkName: "about" */ '@/views/account/Login.vue'),
                meta: {
                    title: '登录'
                }
            },
            {
                path: 'signup',
                name: 'register',
                component: () => import(/* webpackChunkName: "about" */ '@/views/account/Register.vue'),
                meta: {
                    title: '注册'
                }
            },
            {
                path: 'forgotPwd',
                name: 'forgotPwd',
                component: () => import(/* webpackChunkName: "about" */ '@/views/account/ForgotPwd.vue'),
                meta: {
                    title: '忘记密码'
                }
            },
        ]
    },
    {
        path: '/company',
        name: 'company',
        children: [
            {
                path: 'list',
                name: 'companyList',
                component: () => import(/* webpackChunkName: "about" */ '@/views/company/CompanyList.vue'),
                meta: {
                    keepAlive: true,
                    title: '企业列表实录'
                }
            },
            {
                path: 'detail/:uuid',
                name: 'companyDetail',
                component: () => import(/* webpackChunkName: "about" */ '@/views/company/CompanyDetail.vue'),
                meta: {
                    title: '企业详情'
                }
            },
            {
                path: 'release/:uuid',
                name: 'CompanyRelease',
                component: () => import(/* webpackChunkName: "about" */ '@/views/company/CompanyRelease.vue'),
                meta: {
                    title: '企业资料更新',
                    auth: true
                }
            },
        ]
    },
    {
        path: '/legal',
        name: 'legal',
        children: [
            {
                path: 'privacy',
                name: 'privacy',
                component: () => import('@/views/legal/Privacy.vue'),
                meta: {
                    title: '隐私政策'
                }
            },
            {
                path: 'terms',
                name: 'terms',
                component: () => import('@/views/legal/Terms.vue'),
                meta: {
                    title: '用户服务协议'
                }
            },
            {
                path: 'community_guidelines',
                name: 'disclaimer',
                component: () => import('@/views/legal/CommunityGuidelines.vue'),
                meta: {
                    title: '黑鸭社区公约'
                }
            }
        ]
    },
    {
        path: '/help/markdown',
        name: 'markdownGuide',
        component: () => import(/* webpackChunkName: "about" */ '@/views/other/MarkdownGuide.vue'),
        meta: {
            keepAlive: true,
            title: 'Markdown语法指导'
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router
