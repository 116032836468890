import {closeToast, showLoadingToast} from "vant";

export const openLoading = (msg: string) => {
    showLoadingToast({
        message: msg ? msg : '加载中...',
        forbidClick: true,
        duration: 9000
    });
}

export const closeLoading = () => {
    closeToast();
}
