import axios from 'axios';
import localStorageDb from './localStorage';
import {ElMessage} from 'element-plus';
import router from '../router'
import {Base64 as RSA} from 'js-base64'

// axios.defaults.baseURL = 'http://192.168.1.8:9001/api';
axios.defaults.baseURL = 'https://api.heiya.wiung.com/api';
axios.defaults.headers.common['Authorization'] = localStorageDb.get("Authorization") == null ? '' : localStorageDb.get("Authorization");
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
let numberFlag = RSA.encode(new Date().getTime())
let axiosRsa = numberFlag.replaceAll('MT', 'NYO3oI').replaceAll('==', '')
axios.defaults.headers.common['If-None-Metch'] = axiosRsa;

axios.interceptors.response.use(success => {
    if (success.status === 200 && success.data.code === 1) {
        return success.data;
    } else if (success.status === 200 && success.data.code === 0) {
        ElMessage.warning(success.data.msg);
        // return success.data;
    } else if (success.status === 200 && success.data.code === 405) {
        ElMessage.error(success.data.msg);
    }
}, error => {
    if (error.response.status === 504 || error.response.status === 404) {
        ElMessage.error('服务器迷路了( ╯□╰ )，再试一次吧。');
    } else if (error.response.status === 403) {
        ElMessage.warning('权限不足，请联系管理员');
    } else if (error.response.status === 401) {
        if (localStorage.getItem("Authorization")) {
            ElMessage.warning('登录状态已过期，请重新登录！');
            localStorageDb.remove("Authorization");
            localStorageDb.remove("USER");
        } else {
            ElMessage.warning('尚未登录，请登录！')
        }
        // router.push('/account/signin');
        setTimeout(() => {
            window.location.href = '/account/signin'
        }, 1000)
    } else if (error.response.status === 429) {
        ElMessage.warning('已触发安全风控！(￣.￣)...')
    } else {
        ElMessage.error('未知异常!')
    }
    return Promise.reject(error.response.data.error);
});

let myRequest = {
    postRequest(url, params, headers) {
        headers = {
            'Authorization': localStorageDb.get("Authorization")
        };

        return axios({
            method: 'post',
            url: `${url}`,
            data: params,
            headers: headers
        })
    },

    putRequest(url, params) {
        return axios({
            method: 'put',
            url: `${url}`,
            data: params
        })
    },

    getRequest(url, params, headers) {
        let apiUrl = `${url}`;
        apiUrl = getApiUrl(apiUrl, params);
        // 请求
        return axios({
            method: 'get',
            url: apiUrl,
            data: params,
            headers: headers
        })
    },

    deleteRequest(url, params) {
        let apiUrl = `${url}`;
        apiUrl = getApiUrl(apiUrl, params);
        return axios({
            method: 'delete',
            url: apiUrl,
            data: params
        })
    },

    /** 处理字符内容截取 */
    handleContent(content, charLength) {
        if (content == null) {
            return "";
        }
        if (content.length > charLength) {
            return content.substr(0, charLength) + "...";
        } else {
            return content;
        }
    }
}

// 处理参数转换
function getApiUrl(url, params) {
    let apiUrl = url;
    let i = 0;
    for (const key in params) {
        const value = params[key];
        const param = key + "=" + value;
        let s = '?';
        if (i > 0) {
            s = '&';
        }
        apiUrl = apiUrl + s + param;
        i++;
    }
    return apiUrl;
}

export default myRequest


