import myRequest from "@/utils/request";

export function getTopicsList(params) {
    return myRequest.postRequest('/topics/list', params).then(resp => {
        return resp.data;
    });
}

export function getTopicsDetail(params) {
    return myRequest.postRequest('/topics/detail', params).then(resp => {
        return resp.data;
    });
}

export function addTopics(params) {
    return myRequest.postRequest('/topics/add', params).then(resp => {
        return resp;
    });
}
