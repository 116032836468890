<template>
    <div class="topics" @click="toTopicDetail(topic.uuid)">
        <div class="flex-start">
            <div style="width: 35px">
                <AvatarCard :size="35" :title="true" :avatarUrl="topic.user.gravatar" :name="topic.user.username"/>
            </div>

            <div style="margin-left: 15px;">
                <div>
                    <span style="color: #42b983">#{{ topic.dataType.name }}</span>
                    <span style="margin-left: 5px">{{ topic.title }}</span>
                </div>
                <div class="mt10">
                    <el-space :size="20">
                        <el-text size="small" type="info" truncated style="max-width: 60px">
                            <van-icon name="contact" />
                            {{ topic.user.nickname }}
                        </el-text>

                        <el-text size="small" type="info">
                            <el-icon>
                                <Edit/>
                            </el-icon>
                            {{ timeAgo(topic.createTime) }}
                        </el-text>

                        <el-text size="small" type="info" v-if="topic.commentTime">
                            <el-icon>
                                <ChatDotRound/>
                            </el-icon>
                            {{ timeAgo(topic.commentTime) }}
                        </el-text>

                    </el-space>
                </div>
            </div>
        </div>

        <div style="margin-left: 15px;">
            <el-tag type="info" effect="light" round>
                {{ topic.commentNum }}
            </el-tag>
        </div>
    </div>
</template>

<script setup>
import {defineProps} from 'vue'
import {useRouter} from "vue-router";
import AvatarCard from "@/components/AvatarCard.vue";
import {timeAgo} from "@/utils/dateUtil";

const router = useRouter();

const props = defineProps({
    topic: {
        type: Object,
        required: true
    },
})

const toTopicDetail = (uuid) => {
    router.push({name: 'topics', params: {uuid}})
}
</script>

<style scoped>
.topics {
    min-height: 68px;
    border-bottom: 1px solid #F0F0F0A3;
    padding: 20px 15px 15px;
    vertical-align: top;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topics:hover {
    background-color: #f5f5f5;
}
</style>
